<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results?.data || []"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  :total="items.results"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableDebtor',
  data () {
    return {
      headers: [],
      actions: [
        {
          id: 1,
          to: (item) => this.checkTypeDocument(item),
          color: 'blue',
          // target: '_blank',
          name: 'mdi-account-outline',
          tooltip: 'tooltip.goToSailor',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listDistributionSailor,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListDistributionSailor']),
    async getData (params) {
      this.headers = [
        { value: 'sailor.full_name_ukr', text: this.$t('sailor'), align: 'center', title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', total: { global: 'global_form2_distribution', page: 'global_form2_distribution', align: 'center' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', total: { global: 'global_form2_profit', page: 'page_form2_profit', align: 'center' }, sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ]
      await this.getListDistributionSailor({ params: { ...params, ...this.$route.params }, link: this.checkLink() })
    },
    checkTypeDocument (item) {
      switch (this.$route.params.typeDocument) {
        case 'sqc':
        case 'crewing_manager':
          if (!this.headers.find(column => column.value === 'rank')) {
            this.headers.splice(1, 0, {
              value: 'rank',
              text: this.$t('rank'),
              sortable: false })
          }
          return ({ name: 'sailor', params: { id: item.sailor.id } })
        case 'dpd':
          return ({ name: 'sailor', params: { id: item.sailor.id } })
        case 'eti':
          if (!this.headers.find(column => column.value === 'eti')) {
            this.headers.unshift({
              value: 'eti',
              text: this.$t('eti'),
              sortable: false })
          }
          return ({ name: 'sailor', params: { id: item.sailor.id } })
        case 'medical':
          if (!this.headers.find(column => column.value === 'number_document')) {
            this.headers.unshift({ value: 'number_document', text: this.$t('number'), sortable: false })
            this.headers.splice(2, 0, { value: 'position', text: this.$t('position'), sortable: false })
          }
          return ({ name: 'sailor', params: { id: item.sailor.id } })
        case 'portal':
        case 'sc':
          if (!this.headers.find(column => column.value === 'rank')) {
            this.headers.splice(1, 0, { value: 'rank', text: this.$t('rank'), sortable: false })
          }
          return ({ name: 'sailor', params: { id: item.sailor.id } })
        case 'adv_training':
          return ({ name: 'sailor', params: { id: item.sailor.id } })
        default:
          return ({ name: 'sailor', params: { id: item.sailor.id } })
      }
    },
    checkLink () {
      switch (this.$route.params.typeDocument) {
        case 'dpd':
          return 'dpdSailor'
        default:
          return 'sailor'
      }
    }

  }
}
</script>
